import React from "react";
import {
    Container,
    Row,
} from "reactstrap";

export default ({info})=> {
    return (
        <div className="section section-javascript cover-color" id="map">
            <img
                alt="..."
                className="path"
                src={"/images/shapes/path1.png"}
            />
            <img
                alt="..."
                className="path path1"
                src={"/images/shapes/path5.png"}
            />
            <div className="section">
                <Container>
                    <div className="main-title">
                        {info.title}
                    </div>
                    <Row className="justify-content-between align-items-center">
                        <div className="info-logo mt-4">
                            {info.description}
                        </div>
                    </Row>
                    <Row className="justify-content-between align-items-center">
                        <iframe
                            src={info.src}
                            width="1200" height="500" style={{border:0}} allowFullScreen={true} loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"> </iframe>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
