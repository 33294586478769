import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,Routes,Route ,Navigate} from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss?v=1.2.0";
import "./assets/demo/demo.css";
import Main from "./views/main.js";
import Memories from "./views/Memories";
import Holidays from "./views/Holidays";

const App = function () {
    React.useEffect(() => {
        document.body.classList.toggle("index-page");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("index-page");
        };
    },[]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route
                        path="/memories"
                        element={<Memories />}
                    />
                    <Route
                      path="/holidays"
                      element={<Holidays />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

ReactDOM.render(
    <App />,
  document.getElementById("root")
);
