import React from "react";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import PageHeader from "../components/PageHeader/PageHeader.js";
import Footer from "../components/Footer/Footer.js";
import LogoComponent from "../sections/LogoComponent";
import IntroTemplate from "../sections/IntroTemplate";
import {data} from "../data/data"
import Services from "../sections/Services";
import Gallery from "../sections/Gallery";
import OurView from "../sections/OurView";
import BusinessSection from "../sections/BusinessSection";
import {Images} from "../image_paths";
import Map from "../sections/Map";

export default function Holidays() {
  return (
    <>
          <IndexNavbar />
          <div className="wrapper image-cover">
              <PageHeader logo={Images.logo.holidays} />
              <div className="main">
                  <IntroTemplate id={"who-we-are"} isRight={true} number={data.holidays.view.number} title={data.holidays.view.title} description={data.holidays.view.description}/>
                  <OurView title={data.holidays.view.ourView.title} description={data.holidays.view.ourView.description} img={data.holidays.view.ourView.images}/>
                  <div className="cover-color2 p-5"> </div>
                  {data.holidays.view.content.map((e,index)=>{
                      return <div key={index}>
                          <BusinessSection index={index} title={e.title} description={e.description} isMain={index%2===0} img={e.image}/>
                      </div>
                  })}
                  <IntroTemplate id={"our-services"}  isRight={false} number={data.holidays.services.number} title={data.holidays.services.title} description={data.holidays.services.description}/>
                  <Services services={data.holidays.services.content}/>
                  <Gallery title={data.holidays.gallery.title} description={data.holidays.gallery.description} img={data.holidays.gallery.content}/>
                  <Map info={data.holidays.maps} />
              </div>
              <Footer info={data.holidays.contacts} logo={data.logo.holidays.img} socials={data.holidays.socials}  />
          </div>
</>
  );
}
