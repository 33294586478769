import _ from "lodash";

export const Images = {
    logo: {
        hmGroup: {
            title: "HM GROUP LOGO",
            src: require("./assets/img/logo/HM.png").default
        },
        memories: {
            title: "Memories LOGO",
            src: require("./assets/img/logo/memories.png").default
        },
        holidays: {
            title: "Holidays LOGO",
            src: require("./assets/img/logo/holidays.png").default
        },
    },
    galleryMemories: _.range(1, 9, 1).map((e) => {
        return {
            title: `gallery ${e}`,
            src: require(`./assets/img/gallery/n${e}.jpg`).default
        }
    }),
    galleryHolidays: _.range(1, 16, 1).map((e) => {
        return {
            title: `gallery ${e}`,
            src: require(`./assets/img/gallery/holidays${e}.jpg`).default
        }
    }),
    symbols: {
        symbol1: {
            title: "symbol 1",
            src: require("./assets/img/symbols/001.png").default
        },
        symbol2: {
            title: "symbol 2",
            src: require("./assets/img/symbols/002.png").default
        },
        symbol3: {
            title: "symbol 3",
            src: require("./assets/img/symbols/team2.svg").default
        },
        reservation : {
            title : "ticket reservation",
            src: require("./assets/img/symbols/reservation.png").default
        },
        visa : {
            title : "visa reservation",
            src: require("./assets/img/symbols/visa.png").default
        },
        selfie : {
            title : "selfie",
            src: require("./assets/img/symbols/selfie.png").default
        }
    },
    tourismBackgrounds : _.range(1,6,1).map((e)=>{
        return {
            title: `tourism ${e}`,
            src: require(`./assets/img/backgrounds/tourism${e}.jpg`).default
        }
    })
}
