import React from "react";
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import PageHeader from "../components/PageHeader/PageHeader.js";
import LogoComponent from "../sections/LogoComponent";
import {data} from "../data/data"
import {Images} from "../image_paths";

export default function Main() {
  return (
    <>
      <IndexNavbar />
      <div className="wrapper image-cover">
        <PageHeader logo={Images.logo.hmGroup} />
        <div className="main">
          <LogoComponent data={data.logo} />
        </div>
      </div>
    </>
  );
}
