
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Dropdown} from "react-bootstrap"
import {Images} from "../../image_paths";


export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [showLogo, setShowLogo] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  },[]);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
      if (document.documentElement.scrollTop > 400 ||
          document.body.scrollTop > 400) {
          setShowLogo(true)
      }
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
      setShowLogo(false);
    }

  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  const scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
            {
                <NavbarBrand style={{opacity: showLogo ? "1" : "0"}} to="/" tag={Link} id="navbar-brand">
                    <img className="logo-images" src={Images.logo.hmGroup.src} alt={Images.logo.hmGroup.title}/>
                </NavbarBrand>
            }
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="" onClick={(e) => e.preventDefault()}>
                  HM Company
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem>
                <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic">
                        <i className="fa fa-cogs d-lg-none d-xl-none" />
                        Getting started
                    </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="/">
                        <i className="tim-icons icon-bank" />
                        الصفحة الرئيسية
                    </Dropdown.Item>
                    <Dropdown.Item href="/memories">
                            <i className="tim-icons icon-send" />
                            شركة ميموريز
                    </Dropdown.Item>
                    <Dropdown.Item href="/holidays">
                        <i className="tim-icons icon-send" />
                        شركة هوليديز
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
