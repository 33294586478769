import React from "react"

export default ({services})=>{
    return(<>
        <div id="services" className="container">
            <div className="row mx-0 ">{
                services.map((e,i)=>{
                    return <div key={i} className="col-lg-4 col-md-6 col-sm-12 py-3">
                        <div className="card-services py-3">
                            <div className="card-icon text-center mb-3">
                                { e.icon }
                            </div>
                            <h4 className="text-center">{e.title}</h4>
                        </div>
                    </div>
                })
            }

            </div>

        </div>
    </>)
}
