import React from "react"
import Aos from "aos"

export default ({title, description, img, isMain = false}) => {
    Aos.init()
    return (
        <div className="cover-color2 py-5">
            <img
                alt="..."
                className="path"
                src={"/images/temp/path4.png"}
            />
            <div className="container">
                <div dir={ isMain ? "rtl" : "ltr" } className="row mx-0 business-sec">
                    <div data-aos={ isMain ? "zoom-out-right" : "zoom-out-left" } className="col-3 d-flex justify-content-center">
                        <img src={img} alt="image" className="img-contain" />
                    </div>
                    <div data-aos="zoom-out-up" className="col-9">
                        <h2>{title}</h2>
                        <p className="info-description px-5">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
