import React from "react";
import { NavLink as NavLinkDom } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import {Images} from "../../image_paths";

export default function Footer({info,logo, socials}) {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3" className="d-flex justify-content-center">
            <NavLinkDom to="/" >
                <img className="logo" src={Images.logo.hmGroup.src} alt={Images.logo.hmGroup.title} />
            </NavLinkDom>
          </Col>
          <Col md="3">
            <h3>Follow Us</h3>
              <div className="d-flex justify-content-center">
                  <div className="mr-2">
                      <NavLink target="_blank" href={socials.facebook.href} >
                          {
                              socials.facebook.icon
                          }
                      </NavLink>
                  </div>
                  <div className="ml-2">
                      <NavLink target="_blank" href={socials.instagram.href} >
                          {
                              socials.instagram.icon
                          }
                      </NavLink>
                  </div>
              </div>
          </Col>
          <Col className="follow-div mt-3" md="6">
                  <Row>
                      <Col sm={8} className="btn-wrapper profile">
                      {
                          info.map((e,i)=>{
                              return(<div key={i}>
                                      <Button
                                          className="btn-icon btn-neutral btn-round btn-simple"
                                          color="default"
                                          href={e.href}
                                          target="_blank"
                                      >
                                          {e.icon}
                                      </Button>
                                  <span>{" " + e.description}</span>

                              </div>)
                          })
                      }
                      </Col>
                      <Col sm={4} className="text-center mb-3">
                          <img src={logo.src} alt={logo.title}/>
                      </Col>
                  </Row>
              <div>
              </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
