import React from "react";
import {Images} from "../image_paths";
import _ from "lodash";

const Info = function () {
    return (
        <>
            <div>الفرع الرئيسي : سوريا - السويداء - طريق قنوات - فندق العامر بلازا - ط1</div>
            <div>فرع ثاني : سوريا - السويداء - ساحة تشرين - جانب السورية للطيران</div>
        </>
    )
}


const contacts = {
    holidays: [
        {
            icon: <i className="tim-icons icon-email-85 "/>,
            description: "holidays@hmtourism.com",
            href: "mailto:holidays@hmtourism.com"
        },
        ..._.range(2,8,1).map((e)=>{
            return {
                icon: <i className="tim-icons icon-mobile"/>,
                description: `0096394000880${e}`,
                href: `tel:0096394000880${e}`
            }
        }),
        {
            icon: <i className="tim-icons icon-mobile "/>,
            description: "00963940008812",
            href: "tel:00963940008812"
        },
        {
            icon: <i className="tim-icons icon-app "/>,
            description: "0096316221223",
            href: "tel:0096316221223"
        },
    ],
    memories: [
        {
            icon: <i className="tim-icons icon-email-85 "/>,
            description: "memories@hmtourism.com",
            href: "mailto:memories@hmtourism.com"
        },
        {
            icon: <i className="tim-icons icon-mobile"/>,
            description: "00971523060244",
            href: "tel:00971523060244"
        },
        {
            icon: <i className="tim-icons icon-mobile "/>,
            description: "0097143590004",
            href: "tel:0097143590004"
        },
    ]
}

export const data = {
    logo: {
        holidays: {
            img: Images.logo.holidays,
            dir: "fade-right",
            icon: _.slice(contacts.holidays, 0, 3)
        },
        memories: {
            img: Images.logo.memories,
            dir: "fade-left",
            icon: _.slice(contacts.memories, 0, 3)
        }
    },
    memories: {
        socials : {
            facebook : {
                href : "https://www.facebook.com/Memories-Tourism-1883989451827428",
                icon : <i className="fab fa-4x fa-facebook-square"> </i>,
            },
            instagram : {
                href : "https://instagram.com/memories_tourism",
                icon : <i className="fab fa-4x fa-instagram-square"> </i>,
            },
        },
        view: {
            number: "01",
            title: "من نحن؟",
            description: "تأسست شركة ميموريز للسياحة والسفر في إمارة دبي في دولة الإمارات العربية المتحدة عام 1997 وهي من مجموعة HM TOURISM المتخصصة في السياحة الداخلية والخارجية الهادفة لخدمة العملاء من الشركات والأفراد على حد سواء. ",
            ourView: {
                title: "رؤيتنا:",
                description: "تسعى ميموريز للسياحة إلى الريادة في مجال تقديم الخدمات السياحية لزبائنها و شركائها حول العالم ومن أجل ذلك فهي تسعى إلى الوصول إلى مختلف المدن السياحية عبر اتفاقيات مع شركاء جدد لتقديم الخدمات السياحية المتنوعة.",
                images: Images.tourismBackgrounds
            },
            content: [
                {
                    title: "الخدمات السياحية لرجال الأعمال :",
                    description: "تواكب ميموريز للسياحة متطلبات رجال الأعمال من خلال تقديم كافة تسهيلات الإقامة و الحجوزات و التنقلات في كافة أنحاء العالم من خلال شركائها المحليين و الدوليين و تسعى من أجل ذلك بتقديم كافة التسهيلات و الخدمات السياحية لعملائها من درجة رجال الأعمال.",
                    image: Images.symbols.symbol2.src
                },
                {
                    title: "خدمة إسعاد المتعاملين ",
                    description: "إنطلاقاً من رؤية دبي في إسعاد المتعاملين فإن ميموريز للسياحة تسعى من خلال العمل الداؤوب إلى إيصال أفضل الخدمات بالأسعار المنافسة ومتابعة تقييم العملاء للوصول إلى أرقى و أفضل الخدمات. ",
                    image: Images.symbols.symbol1.src
                },
                {
                    title: "فريق عملنا :",
                    description: "تضم ميموريز فريق عمل متكامل من داخل الدولة و خارجها بخبرات و اختصاصات متنوعة مع تقديمنا لبرامج تدريب و تعليم مستمرة تهدف إلى تقديم خدماتنا بجودة و سرعة عالية.",
                    image: Images.symbols.symbol3.src
                },

            ]
        },
        services: {
            number: "02",
            title: "خدماتنا",
            description: "ضمن الاكتشاف في احتياجات السوق تقدم ميموريز خدمات منوعة ترضي حاجات العملاء من خلال استحواذنا و تميزنا بأنظمة حجز متنوعة وكبيرة من خلال شركائنا من مزودين في أوروبا واّسيا وخصوصاً و بقية العالم عموماً.",
            content: [{
                icon: <i className="tim-icons icon-lock-circle"> </i>,
                title: "حجز المجموعات السياحية"
            }, {
                icon: <i className="tim-icons icon-atom"> </i>,
                title: "حجز الفنادق"
            }, {
                icon: <i className="tim-icons icon-bus-front-12 "> </i>,
                title: "خدمات النقل "
            }, {
                icon: <i className="tim-icons icon-atom"> </i>,
                title: "المؤتمرات و الفعاليات"
            }, {
                icon: <i className="tim-icons icon-compass-05"> </i>,
                title: "الجولات السياحية "
            }, {
                icon: <i className="tim-icons icon-book-bookmark"> </i>,
                title: "الطائرات الخاصة "
            }, {
                icon: <i className="tim-icons icon-bell-55"> </i>,
                title: "خدمات التأشيرات "
            }, {
                icon: <i className="tim-icons icon-bulb-63"> </i>,
                title: "الرحلات البحرية الإقليمية و العالمية "
            }, {
                icon: <i className="tim-icons icon-credit-card"> </i>,
                title: "خدمات الاستقبال"
            },

            ]

        },
        gallery: {
            title: "معرض الصور",
            description: " !! ذكرياتنا في كل مكان",
            content: Images.galleryMemories
        },
        contacts: contacts.memories,
        maps : {
            title : "تعرف علينا",
            description : <div dir="rtl">الإمارات العربية المتحدة - دبي - الفرهود - جانب وكالة فورد - بناء سارة - GV</div>,
            src : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.1374707761792!2d55.33730499875436!3d25.25208130639814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d1f72b94a9d%3A0x66ccf4cd4a7634d8!2sSarah%20building!5e0!3m2!1sen!2s!4v1653679881308!5m2!1sen!2s"
        }
    },
    holidays: {
        socials : {
          facebook : {
              href : "https://www.facebook.com/Holidays.Syria",
              icon : <i className="fab fa-4x fa-facebook-square"> </i>,
          },
            instagram : {
                href : "https://www.instagram.com/holidays.syria",
                icon : <i className="fab fa-4x fa-instagram-square"> </i>,
            },
        },
        view: {
            number: "01",
            title: "من نحن؟",
            description: "تأسست شركة Holidays للسياحة والسفر في مدينة السويداء في الجمهورية العربية السورية عام 2005 وهي من مجموعة HM TOURISM المتخصصة في السياحة الداخلية والخارجية الهادفة لخدمة العملاء من الشركات والأفراد على حد سواء.",
            ourView: {
                title: "رؤيتنا:",
                description: "تسعى Holidays للسياحة إلى تقديم كافة الخدمات السياحية لزبائنها بدءً من الحصول على تأشيرة وصولاً الى تذكرة الطائرة بأكثر الأسعار منافسة.",
                images: Images.tourismBackgrounds
            },
            content: [
                {
                    title: "خدمات الحجوزات",
                    description: "من خلال تقديم أنسب الأسعار للمكاتب السياحية من حجوزات طيران محلية وعالمية بالإضافة للحجوزات الفندقية والفيز السياحية والعمل.",
                    image: Images.symbols.reservation.src
                },
                {
                    title: "خدمة الإقامات",
                    description: "تقوم شركة هوليدايز بتقديم كافة خدمات الإقامات السياحية, العمل, إقامات طويلة الأمد لنلبي احتياجات عملائنا داخل وخارج القطر",
                    image: Images.symbols.visa.src
                },
                {
                    title: "تنظيم رحلات سياحية",
                    description: "أمتع الأوقات تقضونها بأماكن سياحية وترفيهية مختلفة تلبي كافة الأذوق وتترك أحلى الذكريات",
                    image: Images.symbols.selfie.src
                },

            ]
        },
        services: {
            number: "02",
            title: "خدماتنا",
            description: "تقوم شركة Holidays بتقديم كافة الخدمات السياحية المميزة من تنظيم رحلات سياحية وعائلية بالإضافة لتوفير خدمات التأشيرات وحجز التنذاكر بأكثر الأسعار منافسة في السوق العربية والعالمية",
            content: [{
                icon: <i className="tim-icons icon-lock-circle"> </i>,
                title: "حجز المجموعات السياحية"
            }, {
                icon: <i className="tim-icons icon-atom"> </i>,
                title: "حجز الفنادق"
            }, {
                icon: <i className="tim-icons icon-bus-front-12 "> </i>,
                title: "خدمات النقل "
            }, {
                icon: <i className="tim-icons icon-bell-55"> </i>,
                title: "خدمات التأشيرات "
            }, {
                icon: <i className="tim-icons icon-bulb-63"> </i>,
                title: "تنظيم الرحلات الداخلية"
            }, {
                icon: <i className="tim-icons icon-credit-card"> </i>,
                title: "خدمات الاستقبال"
            },

            ]

        },
        gallery: {
            title: "صور من رحلاتنا",
            description: "انضموا لعائلتنا ضمن حجوزات الى جميع أنحاء العالم",
            content: Images.galleryHolidays
        },
        contacts: contacts.holidays,
        maps : {
            title : "تعرف علينا",
            description : <Info />,
            src : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d419.6231510299197!2d36.57310374810133!3d32.71302076328535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151bd57d8c7c81ed%3A0xf01b2dfda15d796a!2sAl%20Amer%20Hotel!5e0!3m2!1sen!2s!4v1653682426980!5m2!1sen!2s"
        }
    },
}
