import React from "react";
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import PageHeader from "../components/PageHeader/PageHeader.js";
import Footer from "../components/Footer/Footer.js";
import IntroTemplate from "../sections/IntroTemplate";
import {data} from "../data/data"
import Services from "../sections/Services";
import Gallery from "../sections/Gallery";
import OurView from "../sections/OurView";
import BusinessSection from "../sections/BusinessSection";
import {Images} from "../image_paths";
import Map from "../sections/Map";

export default function Memories() {
  return (
    <>
      <IndexNavbar />
      <div className="wrapper image-cover">
        <PageHeader logo={Images.logo.memories} />
        <div className="main">
          <IntroTemplate id={"who-we-are"} isRight={true} number={data.memories.view.number} title={data.memories.view.title} description={data.memories.view.description}/>
          <OurView title={data.memories.view.ourView.title} description={data.memories.view.ourView.description} img={data.memories.view.ourView.images}/>
            <div className="cover-color2 p-5"> </div>
            {data.memories.view.content.map((e,index)=>{
                return <div key={index}>
                    <BusinessSection index={index} title={e.title} description={e.description} isMain={index%2===0} img={e.image}/>
                </div>
            })}
          <IntroTemplate id={"our-services"}  isRight={false} number={data.memories.services.number} title={data.memories.services.title} description={data.memories.services.description}/>
          <Services services={data.memories.services.content}/>
          <Gallery title={data.memories.gallery.title} description={data.memories.gallery.description} img={data.memories.gallery.content}/>
          <Map info={data.memories.maps} />
        </div>
        <Footer info={data.memories.contacts} logo={data.logo.memories.img} socials={data.memories.socials} />
      </div>
    </>
  );
}
