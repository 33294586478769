import React, {useState} from "react"
import {NavLink} from "react-router-dom";
import Aos from "aos"


export default ({data})=>{
    Aos.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 100,
    })
    return(<>
       <div  className="logo-component">
           <div className="row mx-0 text-white" >
               <div data-aos={data.holidays.dir} className=" col-md-6 col-sm-12  " style={{height:"45vh"}}>
                   <NavLink to="/holidays" >
                       <div className="w-100 h-100 d-flex justify-content-center div-logo position-relative">
                           <img className="div-image" src={data.holidays.img.src} alt={data.holidays.title}/>
                           <div className="icon-div w-100">
                               {
                                   data.holidays.icon.map((e,i)=>{
                                       return<div key={i} className="info-icon">
                                           <span className="mr-3">{e.icon}</span>
                                           {e.description}
                                       </div>
                                   })
                               }
                           </div>
                       </div>
                   </NavLink>
               </div>
               <div data-aos={data.memories.dir} className=" col-md-6 col-sm-12  " style={{height:"45vh"}}>
                   <NavLink to="/memories" >
                       <div className="w-100 h-100 d-flex justify-content-center div-logo position-relative">
                           <img className="div-image" src={data.memories.img.src} alt={data.memories.title}/>
                           <div className="icon-div w-100">
                               {
                                   data.memories.icon.map((e,i)=>{
                                       return<div key={i} className="info-icon">
                                           <span className="mr-3">{e.icon}</span>
                                           {e.description}
                                       </div>
                                   })
                               }
                           </div>
                       </div>
                   </NavLink>
               </div>


           </div>
       </div>
        </>)
}
