import React from "react"
import {SplideSlide,Splide} from "@splidejs/react-splide";

export default ({title,description,img})=>{
    return(<>

        <div id="our-view">
            <div className="img-content">
                <Splide options={{perPage: 1,
                    autoplay: true,
                    interval: 5000,
                    speed: 4000,
                    arrows: false,
                    pagination: false,
                    type: "fade",
                    resetProgress: true,
                    rewind: true,
                    heightRatio: 0.5,
                    pauseOnHover: false,}}>
                    {img.map((img, i)=>{
                        return<SplideSlide key={i}>
                            <img src={img.src} alt={img.title}/>
                        </SplideSlide>
                    })}

                </Splide>
                <div className="cover-background">
                    <div className="container d-flex justify-content-center align-items-center h-100" dir={"rtl"}>
                       <div className="h-50 text-section">
                           <div className="title-card ">
                               <div className="py-5">
                                   {title}
                               </div>
                           </div>
                           <div className="content-card">
                               {description}
                           </div>
                       </div>
                    </div>
                </div>
            </div>

        </div>

        </>)
}

