import React, {useState} from "react"
import Aos from "aos"
import {right} from "@popperjs/core";

export default ({id,number,title,description,isRight=true})=>{
    Aos.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 250,
    })

  return(<>
        <div id={id} className="d-flex section-01">
            <div dir={isRight ? "rtl" : "ltr" } className="w-100 text-center">
                <div className="number-section d-inline" >
                    {number}
                </div>
                <div className="justify-content-center d-inline-block w-75">
                    <div className="container">
                        <h2 className="text-center main-title">{title}</h2>
                        <div data-aos="fade-up" className="info-logo align-right w-100 px-4 pt-4" dir="rtl">
                            <div> {description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>)
}
