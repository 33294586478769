import React from "react";
import classnames from "classnames";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
    Button,
    FormGroup,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    UncontrolledPopover,
    PopoverBody,
    PopoverHeader,
    Modal,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    UncontrolledCarousel,
} from "reactstrap";
import {SplideSlide,Splide} from "@splidejs/react-splide"

export default ({img, title, description})=> {
    return (
        <div className="section section-javascript cover-color" id="gallery">
            <img
                alt="..."
                className="path"
                src={"/images/shapes/path1.png"} //TODO
            />
            <img
                alt="..."
                className="path path1"
                src={"/images/temp//path5.png"}
            />
            <div className="section">
                <Container>
                    <div className="main-title">
                        {title}
                    </div>
                    <Row className="justify-content-between align-items-center">
                        <Col className="mb-5 mb-lg-0" lg="5">
                            <p className="info-logo mt-4">
                                {description}
                            </p>
                        </Col>
                        <Col lg="6">
                           <Splide  dir='ltr' options={{
                               perPage: 1,
                               autoplay: true,
                               interval: 2000,
                               speed: 3000,
                               arrows: false,
                               pagination: false,
                               type: "fade",
                               resetProgress: true,
                               rewind: true,
                               heightRatio: 0.6,
                               pauseOnHover: false,
                           }}>
                               {img.map((e,i)=>{
                                   return<SplideSlide key={i}><img src={e.src} alt={e.title} /></SplideSlide>
                               })}
                           </Splide>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
